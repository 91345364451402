var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: { placeholder: "No project selected" },
      model: {
        value: _vm.selectedProject,
        callback: function ($$v) {
          _vm.selectedProject = $$v
        },
        expression: "selectedProject",
      },
    },
    _vm._l(_vm.project_groups, function (group) {
      return _c(
        "el-option-group",
        { key: group.id, attrs: { label: group.label } },
        _vm._l(group.projects, function (project) {
          return _c(
            "el-option",
            {
              key: project.id,
              attrs: { value: project.id, label: project.name },
            },
            [
              _c("div", { staticClass: "project-row" }, [
                _c("span", { staticClass: "project-name" }, [
                  _vm._v(" " + _vm._s(project.name) + " "),
                ]),
                _c("span", { staticClass: "project-ref" }, [
                  _vm._v(" " + _vm._s(project.ref) + " "),
                ]),
              ]),
            ]
          )
        }),
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }